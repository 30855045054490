import React, {useState} from 'react';




import {
    AspectRatio,
    Box,
    BoxProps,
    Container,
    forwardRef,
    Heading,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import {uploadFile} from "../helpers/uploadFile";
import {Image} from "@chakra-ui/image";
import {Spinner} from "@chakra-ui/spinner";

const first = {
    rest: {
        rotate: "-15deg",
        scale: 0.95,
        x: "-50%",
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        x: "-70%",
        scale: 1.1,
        rotate: "-20deg",
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const second = {
    rest: {
        rotate: "15deg",
        scale: 0.95,
        x: "50%",
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        x: "70%",
        scale: 1.1,
        rotate: "20deg",
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const third = {
    rest: {
        scale: 1.1,
        filter: "grayscale(80%)",
        transition: {
            duration: 0.5,
            type: "tween",
            ease: "easeIn"
        }
    },
    hover: {
        scale: 1.3,
        filter: "grayscale(0%)",
        transition: {
            duration: 0.4,
            type: "tween",
            ease: "easeOut"
        }
    }
};

const PreviewImage = forwardRef((props, ref) => {
    return (
        <Box
            bg="white"
            top="0"
            height="100%"
            width="100%"
            position="absolute"
            borderWidth="1px"
            borderStyle="solid"
            rounded="sm"
            borderColor="gray.400"
            as={motion.div}
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            backgroundImage={`url("https://is1-ssl.mzstatic.com/image/thumb/PurpleSource125/v4/09/1d/21/091d2123-0d9b-00ef-5451-2085893b4cfc/80e9086a-0014-4679-921b-808c392b5d6c_screenshot3.jpeg/750x750bb.jpeg")`}
            {...props}
            ref={ref}
        />
    );
});

const ImageUpload = ({onUploadDone,uploadedImg})=> {
    const controls = useAnimation();
    // const [uploadedImg, setImage] = useState('')
    const [uploadLoading, setUploadLoading] = useState(false)
    // const beforeUpload = file => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //         message.error('You can only upload JPG/PNG file!');
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 2;
    //     if (!isLt2M) {
    //         message.error('Image must smaller than 2MB!');
    //     }
    //     return isJpgOrPng && isLt2M;
    // }
    // const handleUploadChange = info => {
    //     // console.log({info})
    //     if (info.file.status === 'uploading') {
    //         setUploadLoading(true)
    //         return;
    //     }
    //     if (info.file.status === 'done') {
    //         getBase64(info.file.originFileObj, imageUrl =>{
    //             setImage(imageUrl)
    //             setUploadLoading(true)
    //         });
    //         const url = info.file.response.data.secure_url
    //         // console.log(url)
    //         form.setFieldsValue({
    //             image:url
    //         });
    //     }
    // };
    const onFileChange = async file => {

        try {
            setUploadLoading(true)
            const res = await uploadFile(file,'program_images')
            if(res.data) {
                // setImage(res.data.url)
                onUploadDone(res.data.url)
            }
            setUploadLoading(false)

        }
        catch (e) {
setUploadLoading(false)
        }

    }

    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();
    return (
        <Container my="12">
            <AspectRatio width="64" ratio={1}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    shadow="sm"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        shadow: "md"
                    }}
                    as={motion.div}
                    initial="rest"
                    animate="rest"
                    whileHover="hover"
                >
                    <Box position="relative" height="100%" width="100%">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                spacing="4"
                            >
                                {!uploadedImg&&!uploadLoading&&<><Box height="16" width="12" position="relative">
                                    <PreviewImage
                                        variants={first}
                                        backgroundImage="url('https://res.cloudinary.com/media-et/image/upload/v1661780173/app/f3_zhtfiy.jpg')"
                                    />
                                    <PreviewImage
                                        variants={second}
                                        backgroundImage="url('https://res.cloudinary.com/media-et/image/upload/v1661780173/app/f2_kkdnjn.jpg')"
                                    />
                                    <PreviewImage
                                        variants={third}
                                        backgroundImage={`url("https://res.cloudinary.com/media-et/image/upload/v1661780173/app/f1_x4bpol.jpg")`}
                                    />
                                </Box>
                                    <Stack p="8" textAlign="center" spacing="1">
                                        <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                            Drop images here
                                        </Heading>
                                        <Text fontWeight="light">or click to upload</Text>
                                    </Stack>

                                </>}
                                {uploadedImg&&<Box>
                                    <Image src={uploadedImg} alt='Preview Iamge' />
                                </Box>
                                }
                                {uploadLoading&&<Spinner  size='xl'/>

                                }

                            </Stack>
                        </Box>
                        <Input
                            type="file"
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            accept="image/*"
                            onChange={(e) => onFileChange(e.target.files[0])}
                            onDragEnter={startAnimation}
                            onDragLeave={stopAnimation}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    );
}

export  default ImageUpload
