import React from "react";
import { Box, Flex, Text, useColorModeValue,Image,Button,Spacer, Link, SimpleGrid,HStack,VStack, chakra, Stack, List, ListItem, ListIcon, Divider } from "@chakra-ui/react";
import {useTranslation} from 'gatsby-plugin-react-i18next';

import { Step, Steps, useSteps } from 'chakra-ui-steps';

const content = (
    <Flex py={4}>

    </Flex>
);



export const ComplaintStep = props => {
    const {t} = useTranslation();

    const steps = [
        { label: t('Short Briefing'), content },
        { label: t('Complaint Source'), content },
        { label: t('Your Complaint'), content },
        { label: t('Your Details'), content },
        { label: t('Review and Submit'), content },
    ];
    return (
        <Flex flexDir="column" width="100%" height="full">
            <Steps orientation="vertical" activeStep={props.activeStep} colorScheme="orange">
                {steps.map(({ label, content }) => (
                    <Step label={label} key={label}/>
                ))}
            </Steps>

        </Flex>
    );
};
