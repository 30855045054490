import React, {useContext} from 'react';
import {
    Box,
    Text,
    useColorModeValue,
    Image,
    Flex,
    Button,
    Spacer,
    Link,
    SimpleGrid,
    HStack,
    VStack,
    chakra,
    Stack,
    List,
    ListItem,
    ListIcon,
    Divider,
    Textarea,
    StackDivider, FormControl, FormLabel, Select, Checkbox, RadioGroup
} from "@chakra-ui/react";
import {motion} from "framer-motion";
import {Trans, useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import {FileComplaintContext} from "../../helpers/contexts";
import {useQuery} from "@apollo/client";
import ImageUpload from "../imageUpload";
import {FormErrorMessage} from "@chakra-ui/form-control";
import {Switch} from "@chakra-ui/switch";

const ComplainantDetail = ({errors, values, handleChange,setFieldValue}) => {
    const data =  useContext(FileComplaintContext);

    const category = data.complaintCategories.map(c=>{
        const name = c.name.reduce((accumulator, value) => {
            return {...accumulator, [value['lang']]: value['value']};
        }, {});
        return {...c,name}
    })// data.category.edges.map(e=>e.node)
    // console.log({category})


    const { language} = useI18next();
    const {t} = useTranslation();

    return (
        <motion.main
            style={{width:"100%"}}

            initial={{opacity: 0, y: 200}}
            animate={{opacity: 1, x: 0, y: 0}}
            transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}>
        <Box>
            <VStack
                divider={<StackDivider borderColor='gray.200' />}
                spacing={4}
                align='stretch'
            >
            Complainant Detail

                <FormControl as='fieldset' isInvalid={!!errors.category}>
                    <FormLabel as='legend'>  <h1><Trans>Under What Category can we Classify your complaint?</Trans></h1></FormLabel>
                    <Select placeholder={t('Select Option')} name="category" value={values.category} onChange={handleChange} >
                        {category.map(p=> <option value={p.categoryId}>{p.name[language]}</option>)}

                    </Select>
                    {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                    <FormErrorMessage>{errors.category}</FormErrorMessage>
                </FormControl>
                <FormControl as='fieldset' isInvalid={!!errors.detailComplaint}>
                <FormLabel as='legend'>  <h1><Trans>Your Detail Complaint</Trans></h1></FormLabel>
                    <Textarea name="detailComplaint" value={values.detailComplaint} onChange={handleChange} placeholder={t('Type your detail complaint here, be sure to omit personal information')} />
                {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                    <FormErrorMessage>{errors.detailComplaint}</FormErrorMessage>
            </FormControl>

                <FormControl>
                    <FormLabel htmlFor='mediaResponded' mb='0'>
                        <Trans>Have you contacted the Media about this complaint?</Trans>
                    </FormLabel>
                <Switch size="lg" name="mediaResponded" isChecked={values.mediaResponded} onChange={handleChange}></Switch>

                </FormControl>

                {values.mediaResponded&&<FormControl as='fieldset'>
                    <FormLabel as='legend'><h1><Trans>What was the response given?</Trans></h1></FormLabel>
                    <Textarea name="mediaResponse" value={values.mediaResponse} onChange={handleChange}
                              placeholder={t('Type short version of the given response')}/>
                    {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                </FormControl>}
                <FormControl as='fieldset'>
                    <FormLabel as='legend'>  <h1><Trans>Any Photo/Screenshot for Evidence?</Trans></h1></FormLabel>

                    <ImageUpload uploadedImg={values.evidencePicture} onUploadDone={val=>setFieldValue('evidencePicture',val)} />
                </FormControl>
            </VStack>
        </Box>
        </motion.main>
    );
};

export default ComplainantDetail;
