import React, {useState, createContext, useContext} from "react"
import { Box, Text, useColorModeValue,Image, Flex,Button,Spacer, Link, SimpleGrid,HStack,VStack, chakra, Stack, List, ListItem, ListIcon, Divider } from "@chakra-ui/react";
import {ComplaintStep} from "../components/stepper";
import {useSteps} from "chakra-ui-steps";
import ComplaintWizardForm from "../components/complaint-form/comlaintWizard";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {useQuery} from "@apollo/client";
import {Q_ALL_COMPLAINTS_CATEGORIES} from "../gql/complaintCategories";
import ContextTest from "../components/complaint-form/contextTest";
import {FileComplaintContext} from "../helpers/contexts"

const FileComplaintPage = ({data}) => {
// console.log({data})
    const {data:categories, loading, error} = useQuery(Q_ALL_COMPLAINTS_CATEGORIES);

    return (
        <FileComplaintContext.Provider value={{...data,complaintCategories:categories?.allComplaintCategories||[]}}>
<Layout>

            <Flex marginTop={-50}
                  direction="column"
                minH="full"
                bg="gray.100"
                py={[6, 6]}
                justify="center"
            >
                <Box pos="relative" py={3}  mx={{sm:0, md: "auto" }}>
                    <Box
                        pos="absolute"
                        inset={0}
                        bgGradient="linear(to-r,cyan.400,blue.500)"
                        shadow="lg"
                        transform={["skewY(-6deg)", "rotate(-6deg)"]}
                        rounded={{ sm: "3xl" }}
                    ></Box>
                    <Box
                        pos="relative"
                        bg="white"
                        shadow="lg"
                        rounded={{ sm: "3xl" }}

                    >

                       <ComplaintWizardForm/>
                       {/*<ContextTest/>*/}

                    </Box>
                </Box>
            </Flex>

</Layout>
         </FileComplaintContext.Provider>

    );
}




export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
   briefingScreen: contentfulScreens(node_locale: {eq: $language}, screenId: {eq: "briefing"}){
    title,
    text {
      raw
    }
  },
    complaintTypeScreen: contentfulScreens(node_locale: {eq: $language}, screenId: {eq: "type"}){
    title,
    text {
      raw
    }
  },
  programs: allProgramsJson {
  edges {
    
    node {
        programId
        name {
          am,
          en
        },
        channel
        category
    }
    
  }
  
},
channels:   allChannelsJson {

  edges {

  node {
     channelId
     mediaType
    name {en,am}
    }
    }
    }
  
  category:  allComplaintCategoryJson {

  edges {

  node {
    name {en,am}
    

  }}}


  
  
  }
`;

export default FileComplaintPage




