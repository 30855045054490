import React, {useContext} from 'react';
import 'react-phone-number-input/style.css'
import {
    Box,
    Text,
    useColorModeValue,
    Image,
    Flex,
    Button,
    Spacer,
    Link,
    SimpleGrid,
    HStack,
    VStack,
    chakra,
    Stack,
    List,
    ListItem,
    ListIcon,
    Divider,
    Textarea,
    StackDivider, FormControl, FormLabel, Select, Checkbox, RadioGroup, Radio, FormHelperText
} from "@chakra-ui/react";
import {motion} from "framer-motion";
import {Trans, useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import {Input} from "@chakra-ui/input";
import {FileComplaintContext} from "../../helpers/contexts";
import PhoneInput from 'react-phone-number-input'
import {FormErrorMessage} from "@chakra-ui/form-control";
// import PhoneNumberInput from "../UI/PhoneNumberInput";
// import { COUNTRIES } from "../UI/countries"

const cities = [
    {
        id:"Addis Ababa",
        name: {
            am:'አዲስ አበባ',
            en:'Addis Ababa',
        },
        subcities:["Addis Ketema","Akaky Kaliti"," Arada"," Bole", "Gullele","Kirkos"," Kolfe Keranio"," Lideta"," Nifas Silk-Lafto", "Yeka", "Lemi Kura"]


    },

        {
            "id": "Abiy Addi",
            "name": {
                "en": "Abiy Addi",
                "am": "Abiy Addi"
            }
        },
        {
            "id": "Adama (Nazareth\/Nazret)",
            "name": {
                "en": "Adama (Nazareth\/Nazret)",
                "am": "Adama (Nazareth\/Nazret)"
            }
        },
        {
            "id": "Addis Alem (Ejersa)",
            "name": {
                "en": "Addis Alem (Ejersa)",
                "am": "Addis Alem (Ejersa)"
            }
        },
        {
            "id": "Addis Zemen",
            "name": {
                "en": "Addis Zemen",
                "am": "Addis Zemen"
            }
        },
        {
            "id": "Adigrat",
            "name": {
                "en": "Adigrat",
                "am": "Adigrat"
            }
        },
        {
            "id": "Adwa",
            "name": {
                "en": "Adwa",
                "am": "Adwa"
            }
        },
        {
            "id": "Agaro",
            "name": {
                "en": "Agaro",
                "am": "Agaro"
            }
        },
        {
            "id": "Akaki",
            "name": {
                "en": "Akaki",
                "am": "Akaki"
            }
        },
        {
            "id": "Alaba (Kulito, Quliito)",
            "name": {
                "en": "Alaba (Kulito, Quliito)",
                "am": "Alaba (Kulito, Quliito)"
            }
        },
        {
            "id": "Alitena",
            "name": {
                "en": "Alitena",
                "am": "Alitena"
            }
        },
        {
            "id": "Amba Mariam",
            "name": {
                "en": "Amba Mariam",
                "am": "Amba Mariam"
            }
        },
        {
            "id": "Ambo",
            "name": {
                "en": "Ambo",
                "am": "Ambo"
            }
        },
        {
            "id": "Ankober",
            "name": {
                "en": "Ankober",
                "am": "Ankober"
            }
        },
        {
            "id": "Arba Minch",
            "name": {
                "en": "Arba Minch",
                "am": "Arba Minch"
            }
        },
        {
            "id": "Arboye",
            "name": {
                "en": "Arboye",
                "am": "Arboye"
            }
        },
        {
            "id": "Asaita",
            "name": {
                "en": "Asaita",
                "am": "Asaita"
            }
        },
        {
            "id": "Asella",
            "name": {
                "en": "Asella",
                "am": "Asella"
            }
        },
        {
            "id": "Asosa",
            "name": {
                "en": "Asosa",
                "am": "Asosa"
            }
        },
        {
            "id": "Awasa",
            "name": {
                "en": "Awasa",
                "am": "Awasa"
            }
        },
        {
            "id": "Awash",
            "name": {
                "en": "Awash",
                "am": "Awash"
            }
        },
        {
            "id": "Axum",
            "name": {
                "en": "Axum",
                "am": "Axum"
            }
        },
        {
            "id": "Alamata",
            "name": {
                "en": "Alamata",
                "am": "Alamata"
            }
        },
        {
            "id": "Babille",
            "name": {
                "en": "Babille",
                "am": "Babille"
            }
        },
        {
            "id": "Baco",
            "name": {
                "en": "Baco",
                "am": "Baco"
            }
        },
        {
            "id": "Badme",
            "name": {
                "en": "Badme",
                "am": "Badme"
            }
        },
        {
            "id": "Bahir Dar",
            "name": {
                "en": "Bahir Dar",
                "am": "Bahir Dar"
            }
        },
        {
            "id": "Bati",
            "name": {
                "en": "Bati",
                "am": "Bati"
            }
        },
        {
            "id": "Bedele",
            "name": {
                "en": "Bedele",
                "am": "Bedele"
            }
        },
        {
            "id": "Beica",
            "name": {
                "en": "Beica",
                "am": "Beica"
            }
        },
        {
            "id": "Bichena",
            "name": {
                "en": "Bichena",
                "am": "Bichena"
            }
        },
        {
            "id": "Bishoftu",
            "name": {
                "en": "Bishoftu",
                "am": "Bishoftu"
            }
        },
        {
            "id": "Bonga",
            "name": {
                "en": "Bonga",
                "am": "Bonga"
            }
        },
        {
            "id": "Burie Damote",
            "name": {
                "en": "Burie Damote",
                "am": "Burie Damote"
            }
        },
        {
            "id": "Butajira",
            "name": {
                "en": "Butajira",
                "am": "Butajira"
            }
        },
        {
            "id": "Ciro",
            "name": {
                "en": "Ciro",
                "am": "Ciro"
            }
        },
        {
            "id": "Chencha",
            "name": {
                "en": "Chencha",
                "am": "Chencha"
            }
        },
        {
            "id": "Chuahit",
            "name": {
                "en": "Chuahit",
                "am": "Chuahit"
            }
        },
        {
            "id": "Dabat",
            "name": {
                "en": "Dabat",
                "am": "Dabat"
            }
        },
        {
            "id": "Dangila",
            "name": {
                "en": "Dangila",
                "am": "Dangila"
            }
        },
        {
            "id": "Debarq",
            "name": {
                "en": "Debarq",
                "am": "Debarq"
            }
        },
        {
            "id": "Debre Berhan",
            "name": {
                "en": "Debre Berhan",
                "am": "Debre Berhan"
            }
        },
        {
            "id": "Debre Marqos",
            "name": {
                "en": "Debre Marqos",
                "am": "Debre Marqos"
            }
        },
        {
            "id": "Debre Tabor",
            "name": {
                "en": "Debre Tabor",
                "am": "Debre Tabor"
            }
        },
        {
            "id": "Debre Werq",
            "name": {
                "en": "Debre Werq",
                "am": "Debre Werq"
            }
        },
        {
            "id": "Debre Zebit",
            "name": {
                "en": "Debre Zebit",
                "am": "Debre Zebit"
            }
        },
        {
            "id": "Dejen",
            "name": {
                "en": "Dejen",
                "am": "Dejen"
            }
        },
        {
            "id": "Delgi",
            "name": {
                "en": "Delgi",
                "am": "Delgi"
            }
        },
        {
            "id": "Dembidolo",
            "name": {
                "en": "Dembidolo",
                "am": "Dembidolo"
            }
        },
        {
            "id": "Dessie (Dese)",
            "name": {
                "en": "Dessie (Dese)",
                "am": "Dessie (Dese)"
            }
        },
        {
            "id": "Dila",
            "name": {
                "en": "Dila",
                "am": "Dila"
            }
        },
        {
            "id": "DilYibza (Beyeda)",
            "name": {
                "en": "DilYibza (Beyeda)",
                "am": "DilYibza (Beyeda)"
            }
        },
        {
            "id": "Dire Dawa",
            "name": {
                "en": "Dire Dawa",
                "am": "Dire Dawa"
            }
        },
        {
            "id": "Dolo Bay",
            "name": {
                "en": "Dolo Bay",
                "am": "Dolo Bay"
            }
        },
        {
            "id": "Dolo Odo",
            "name": {
                "en": "Dolo Odo",
                "am": "Dolo Odo"
            }
        },
        {
            "id": "Durame",
            "name": {
                "en": "Durame",
                "am": "Durame"
            }
        },
        {
            "id": "Finicha'a",
            "name": {
                "en": "Finicha'a",
                "am": "Finicha'a"
            }
        },
        {
            "id": "Fiche",
            "name": {
                "en": "Fiche",
                "am": "Fiche"
            }
        },
        {
            "id": "Finote Selam",
            "name": {
                "en": "Finote Selam",
                "am": "Finote Selam"
            }
        },
        {
            "id": "Freweyni",
            "name": {
                "en": "Freweyni",
                "am": "Freweyni"
            }
        },
        {
            "id": "Gambela",
            "name": {
                "en": "Gambela",
                "am": "Gambela"
            }
        },
        {
            "id": "Gelemso",
            "name": {
                "en": "Gelemso",
                "am": "Gelemso"
            }
        },
        {
            "id": "Ghimbi",
            "name": {
                "en": "Ghimbi",
                "am": "Ghimbi"
            }
        },
        {
            "id": "Ginir",
            "name": {
                "en": "Ginir",
                "am": "Ginir"
            }
        },
        {
            "id": "Goba",
            "name": {
                "en": "Goba",
                "am": "Goba"
            }
        },
        {
            "id": "Gode",
            "name": {
                "en": "Gode",
                "am": "Gode"
            }
        },
        {
            "id": "Gondar",
            "name": {
                "en": "Gondar",
                "am": "Gondar"
            }
        },
        {
            "id": "Gongoma",
            "name": {
                "en": "Gongoma",
                "am": "Gongoma"
            }
        },
        {
            "id": "Gore",
            "name": {
                "en": "Gore",
                "am": "Gore"
            }
        },
        {
            "id": "Gorgora",
            "name": {
                "en": "Gorgora",
                "am": "Gorgora"
            }
        },
        {
            "id": "Harar (or Harer)",
            "name": {
                "en": "Harar (or Harer)",
                "am": "Harar (or Harer)"
            }
        },
        {
            "id": "Hayq",
            "name": {
                "en": "Hayq",
                "am": "Hayq"
            }
        },
        {
            "id": "Holeta",
            "name": {
                "en": "Holeta",
                "am": "Holeta"
            }
        },
        {
            "id": "Hosaena",
            "name": {
                "en": "Hosaena",
                "am": "Hosaena"
            }
        },
        {
            "id": "Humera",
            "name": {
                "en": "Humera",
                "am": "Humera"
            }
        },
        {
            "id": "Imi",
            "name": {
                "en": "Imi",
                "am": "Imi"
            }
        },
        {
            "id": "Jijiga",
            "name": {
                "en": "Jijiga",
                "am": "Jijiga"
            }
        },
        {
            "id": "Jimma",
            "name": {
                "en": "Jimma",
                "am": "Jimma"
            }
        },
        {
            "id": "Jinka",
            "name": {
                "en": "Jinka",
                "am": "Jinka"
            }
        },
        {
            "id": "Kabri Dar",
            "name": {
                "en": "Kabri Dar",
                "am": "Kabri Dar"
            }
        },
        {
            "id": "Kebri Mangest",
            "name": {
                "en": "Kebri Mangest",
                "am": "Kebri Mangest"
            }
        },
        {
            "id": "Kobo",
            "name": {
                "en": "Kobo",
                "am": "Kobo"
            }
        },
        {
            "id": "Kombolcha",
            "name": {
                "en": "Kombolcha",
                "am": "Kombolcha"
            }
        },
        {
            "id": "Konso",
            "name": {
                "en": "Konso",
                "am": "Konso"
            }
        },
        {
            "id": "Kulubi",
            "name": {
                "en": "Kulubi",
                "am": "Kulubi"
            }
        },
        {
            "id": "Lalibela",
            "name": {
                "en": "Lalibela",
                "am": "Lalibela"
            }
        },
        {
            "id": "Maji",
            "name": {
                "en": "Maji",
                "am": "Maji"
            }
        },
        {
            "id": "Maychew",
            "name": {
                "en": "Maychew",
                "am": "Maychew"
            }
        },
        {
            "id": "Mek'ele",
            "name": {
                "en": "Mek'ele",
                "am": "Mek'ele"
            }
        },
        {
            "id": "Mendi",
            "name": {
                "en": "Mendi",
                "am": "Mendi"
            }
        },
        {
            "id": "Metemma",
            "name": {
                "en": "Metemma",
                "am": "Metemma"
            }
        },
        {
            "id": "Metu",
            "name": {
                "en": "Metu",
                "am": "Metu"
            }
        },
        {
            "id": "Mizan Teferi",
            "name": {
                "en": "Mizan Teferi",
                "am": "Mizan Teferi"
            }
        },
        {
            "id": "Mojo",
            "name": {
                "en": "Mojo",
                "am": "Mojo"
            }
        },
        {
            "id": "Mota",
            "name": {
                "en": "Mota",
                "am": "Mota"
            }
        },
        {
            "id": "Moyale",
            "name": {
                "en": "Moyale",
                "am": "Moyale"
            }
        },
        {
            "id": "Negash",
            "name": {
                "en": "Negash",
                "am": "Negash"
            }
        },
        {
            "id": "Negele Boran",
            "name": {
                "en": "Negele Boran",
                "am": "Negele Boran"
            }
        },
        {
            "id": "Nekemte",
            "name": {
                "en": "Nekemte",
                "am": "Nekemte"
            }
        },
        {
            "id": "Shashamane",
            "name": {
                "en": "Shashamane",
                "am": "Shashamane"
            }
        },
        {
            "id": "Shire (or Inda Selassie)",
            "name": {
                "en": "Shire (or Inda Selassie)",
                "am": "Shire (or Inda Selassie)"
            }
        },
        {
            "id": "Shilavo",
            "name": {
                "en": "Shilavo",
                "am": "Shilavo"
            }
        },
        {
            "id": "Sodo (Wolaita Sodo)",
            "name": {
                "en": "Sodo (Wolaita Sodo)",
                "am": "Sodo (Wolaita Sodo)"
            }
        },
        {
            "id": "Sodore",
            "name": {
                "en": "Sodore",
                "am": "Sodore"
            }
        },
        {
            "id": "Sokoru",
            "name": {
                "en": "Sokoru",
                "am": "Sokoru"
            }
        },
        {
            "id": "Tenta",
            "name": {
                "en": "Tenta",
                "am": "Tenta"
            }
        },
        {
            "id": "Tiya",
            "name": {
                "en": "Tiya",
                "am": "Tiya"
            }
        },
        {
            "id": "Tippi",
            "name": {
                "en": "Tippi",
                "am": "Tippi"
            }
        },
        {
            "id": "Tullu Milki",
            "name": {
                "en": "Tullu Milki",
                "am": "Tullu Milki"
            }
        },
        {
            "id": "Turmi",
            "name": {
                "en": "Turmi",
                "am": "Turmi"
            }
        },
        {
            "id": "Wacca",
            "name": {
                "en": "Wacca",
                "am": "Wacca"
            }
        },
        {
            "id": "Walwal",
            "name": {
                "en": "Walwal",
                "am": "Walwal"
            }
        },
        {
            "id": "Werder",
            "name": {
                "en": "Werder",
                "am": "Werder"
            }
        },
        {
            "id": "Wereta",
            "name": {
                "en": "Wereta",
                "am": "Wereta"
            }
        },
        {
            "id": "Woldia",
            "name": {
                "en": "Woldia",
                "am": "Woldia"
            }
        },
        {
            "id": "Wolaita Sodo (Sodo)",
            "name": {
                "en": "Wolaita Sodo (Sodo)",
                "am": "Wolaita Sodo (Sodo)"
            }
        },
        {
            "id": "Waliso",
            "name": {
                "en": "Waliso",
                "am": "Waliso"
            }
        },
        {
            "id": "Wolleka",
            "name": {
                "en": "Wolleka",
                "am": "Wolleka"
            }
        },
        {
            "id": "Wuchale",
            "name": {
                "en": "Wuchale",
                "am": "Wuchale"
            }
        },
        {
            "id": "Yabelo",
            "name": {
                "en": "Yabelo",
                "am": "Yabelo"
            }
        },
        {
            "id": "Yeha",
            "name": {
                "en": "Yeha",
                "am": "Yeha"
            }
        },
        {
            "id": "Yirga Alem",
            "name": {
                "en": "Yirga Alem",
                "am": "Yirga Alem"
            }
        },
        {
            "id": "Ziway",
            "name": {
                "en": "Ziway",
                "am": "Ziway"
            }
        }


]
const PersonalDetail = ({errors, values, handleChange,setFieldValue}) => {
    // const category = data.category.edges.map(e=>e.node)
    const { language} = useI18next();
    const {t} = useTranslation();
    const data =  useContext(FileComplaintContext);
    // const countryOptions = COUNTRIES.map(({ name, iso }) => ({
    //     label: name,
    //     value: iso
    // }));
    return (
        <motion.main
            style={{width:"100%"}}

            initial={{opacity: 0, y: 200}}
            animate={{opacity: 1, x: 0, y: 0}}
            transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}>
            <Box>
                <VStack
                    divider={<StackDivider borderColor='gray.200' />}
                    spacing={4}
                    align='stretch'
                >

                    <Checkbox name="fileAnonymously" disabled={values.complaintType==='complaint'}  isChecked={values.fileAnonymously} onChange={handleChange}><Trans>File Complaint Anonymously</Trans></Checkbox>
                    {!values.fileAnonymously&&<>
                        <FormControl as='fieldset'>
                            <FormLabel as='legend'><h1><Trans>How do you want to submit your complaint?</Trans></h1>
                            </FormLabel>
                            <RadioGroup name="applicant.applicantType" value={values.applicant.applicantType}
                                        onChange={val => setFieldValue('applicant.applicantType', val)}>
                                <HStack spacing='24px'>
                                    <Radio value='individual'><Trans>File as Individual</Trans></Radio>
                                    <Radio value='company'><Trans>File as Company</Trans></Radio>
                                </HStack>
                            </RadioGroup>
                            {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                        </FormControl>
                        {values.applicant.applicantType === 'individual' &&
                        <FormControl as='fieldset' isInvalid={!!errors.applicant?.name || !!errors.applicant?.lastName }>
                            <FormLabel as='legend'><h1><Trans>Full Name</Trans></h1></FormLabel>
                            <HStack>
                                <Input name="applicant.name" placeholder={t('Name')} value={values.applicant.name}
                                       onChange={handleChange}/>
                                <Input placeholder={t('Father Name')} name='applicant.lastName'
                                       value={values.applicant.lastName} onChange={handleChange}/>
                            </HStack>

                            {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                            <FormErrorMessage>{errors.applicant?.name} {errors.applicant?.lastName}</FormErrorMessage>
                        </FormControl>}

                        {values.applicant.applicantType === 'company' && <><FormControl as='fieldset'>
                            <FormLabel as='legend'><h1><Trans>Company Name</Trans></h1></FormLabel>
                            <Input name="applicant.name" placeholder={t('Name')} value={values.applicant.name}
                                   onChange={handleChange}/>

                            {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                        </FormControl>
                            <FormControl as='fieldset'>
                                <FormLabel as='legend'><h1><Trans>Tin Number</Trans></h1></FormLabel>
                                <Input name="applicant.tinNo" placeholder={t('Tin Number')} value={values.applicant.tinNo}
                                       onChange={handleChange}/>

                                {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                            </FormControl>

                        </>}
                        <FormControl as='fieldset' isInvalid={!!errors.applicant?.phoneNumber}>
                            <FormLabel as='legend'><h1><Trans>Phone Number</Trans></h1></FormLabel>
                            {/*<Input name="applicant.phoneNumber" value={values.applicant.phoneNumber} onChange={handleChange}*/}
                            {/*       placeholder={t('Phone Number')}/>*/}

                            <PhoneInput
                                placeholder="Enter phone number"
                                defaultCountry="ET"
                                name="applicant.phoneNumber"
                                value={values.applicant.phoneNumber}
                                onChange={e => setFieldValue('applicant.phoneNumber', e)}/>
                            {/*<PhoneNumberInput*/}
                            {/*    value={values.applicant.phoneNumber}*/}
                            {/*    name="applicant.phoneNumber"*/}
                            {/*    country="ETH"*/}
                            {/*    options={countryOptions}*/}
                            {/*    placeholder="9xx"*/}
                            {/*    onChange={e => setFieldValue('applicant.phoneNumber', e)}*/}
                            {/*/>*/}

                            <FormHelperText>{values.applicant.phoneNumber}</FormHelperText>
                            <FormErrorMessage>{errors.applicant?.phoneNumber}</FormErrorMessage>
                        </FormControl>

                        <FormControl as='fieldset'>
                            <FormLabel as='legend'><h1><Trans>Address</Trans></h1></FormLabel>


                                <HStack>
                                    <Select name="applicant.city" placeholder={t('City')} value={values.applicant.city}
                                            onChange={handleChange}>
                                        {cities.map(c=> <option value={c.id}>{c.name[language]}</option>)}

                                    </Select>


                                    {values.applicant.city&&<Select name="applicant.subcity" placeholder={t('SubCity')}
                                             value={values.applicant.subcity}
                                             onChange={handleChange}>
                                        {((cities.find(c=>values.applicant.city===c.id).subcities) || []).map(s => <option
                                            value={s}>{s}</option>)}

                                    </Select>
                                    }
                                    <Input name="applicant.woreda" value={values.applicant.woreda} onChange={handleChange}
                                           placeholder={t('Woreda')}/>
                                </HStack>




                        </FormControl>


                        <FormControl as='fieldset'>
                            <FormLabel as='legend'><h1><Trans>Address Line</Trans></h1></FormLabel>


                            <Textarea
                                name="applicant.addressLine" placeholder={t('Input your address line here')} value={values.applicant.addressLine}
                                onChange={handleChange}
                                size='sm'
                            />




                        </FormControl>





                    </>}
                </VStack>
            </Box>
        </motion.main>
    );
};

export default PersonalDetail;
