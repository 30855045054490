import React, {useContext} from 'react';
import {Box, Checkbox, Text, VStack} from '@chakra-ui/react'
import {motion} from "framer-motion";
import {Trans, useI18next, useTranslation} from "gatsby-plugin-react-i18next";
import {FileComplaintContext} from "../../helpers/contexts";
const ComplaintSummery = ({errors, values, handleChange}) => {
    const {mediaType,channelId, programId,applicant, mediaResponse,detailComplaint,mediaResponded,incidentDate} = values
const {applicantType, name, phone} = applicant
    const data =  useContext(FileComplaintContext);
    const { language} = useI18next();
    const programs = data.programs.edges.map(e=>e.node)
    const channles = data.channels.edges.map(e=>e.node)
    const savedProgram = programs.find(p=>p.programId==programId)
    const program = savedProgram?savedProgram.name[language]:programId
    const channel = channles.find(c=>c.channelId==channelId)?.name[language]
    const {t} = useTranslation();
    return (
        <motion.main
            style={{width:"100%"}}

            initial={{opacity: 0, y: 200}}
            animate={{opacity: 1, x: 0, y: 0}}
            transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}>
        <Box>

            <Text mb={10} fontSize="3xl" fontWeight='bold' >{values.complaintType==='complaint'?<Trans>Make Sure we've got your complaint right</Trans>:<Trans>Make Sure we've got your tip right</Trans>}</Text>
            <Text fontSize="xl">
                {/*{t('complaintSummerySource',{mediaType:`$t(${mediaType})`,program,channel})}*/}

                <Trans t={t} i18nKey="complaintSummerySource">My {{complaintType:t(values.complaintType)}} is about a <strong>{{mediaType:t(mediaType)}}</strong> program called <strong>{{program}}</strong> broadcast on <strong>{{channel}}</strong></Trans>
            </Text>
            <Text fontSize="xl">
                {/*{t('complaintSummeryApplicant',{applicantType:`$t(${applicant.applicantType})`})}*/}
                <Trans  t={t} i18nKey="complaintSummeryApplicant">I am filing the complaint as <strong>{{applicantType:t(applicant.applicantType)}}</strong><br/>
                    Applicant {{name:applicant.name}} {{lastName:applicant.lastName}} <br/>
                    Phone Number {{phone:applicant.phoneNumber}}
                </Trans>
            </Text>

            <Text><Trans>My Complaint is</Trans></Text>
            <Text fontSize="xl">
                {detailComplaint}
            </Text>
            {mediaResponded&&<Box><Text><Trans>I've contacted the media about this complaint</Trans></Text>
            <Text><Trans>Got the Response</Trans></Text>
                <Text>{mediaResponse}</Text></Box>}

            {/*<Checkbox name="isPrivate" isChecked={values.isPrivate} onChange={handleChange}><Trans>Make this complaint Private</Trans></Checkbox>*/}

        </Box>
        </motion.main>
    );
};

export default ComplaintSummery;
