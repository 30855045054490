import gql from "graphql-tag";

export const Q_ALL_COMPLAINTS_CATEGORIES = gql`
  query {
  allComplaintCategories {
    id,
    name {
      lang,
      value
    }
    
  }
}
`;
