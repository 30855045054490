import React, {useContext, useState} from 'react';
import {Box, FormControl, RadioGroup, Text, VStack} from "@chakra-ui/react";
import {AnimatePresence, motion} from "framer-motion";
import {graphql, StaticQuery, useStaticQuery} from "gatsby";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next";
// import {useBriefingAm, useBriefingEn} from "../../hooks/briefingScreen";
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {FileComplaintContext} from "../../helpers/contexts";
import {useRadio, useRadioGroup} from "@chakra-ui/radio";
import {HStack} from "@chakra-ui/layout";
import {Button, IconButton} from "@chakra-ui/button";
import {ArrowBackIcon} from "@chakra-ui/icons";
// import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
// const options = {
//     renderMark: {
//         [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
//     },
//     renderNode: {
//         [INLINES.HYPERLINK]: (node, children) => {
//             const { uri } = node.data
//             return (
//                 <a href={uri} className="underline">
//                     {children}
//                 </a>
//             )
//         },
//         [BLOCKS.HEADING_2]: (node, children) => {
//             return <h2>{children}</h2>
//         },
//     },
// }

function RadioCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as='label'>
            <input {...input} />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                borderColor= 'blue.600'
                _checked={{
                    bg: 'blue.600',
                    color: 'white',
                    borderColor: 'blue.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                    borderColor: 'blue.600',
                }}
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    )
}



const ComplaintBreif = ({values, handleChange, setFieldValue}) => {
    const options = [{
        value:'complaint',
        name:{
            am:'ቅሬታ',
            en:'Complaint'
        },
        desc:{
            am:'በአንድ ግለሰብ ወይም አንድ ድርጅት ላይ ጉዳት የሚያደርስ ሆኖ ሲገኝ በይዘቱ የተጎዳው አካል የሚቀርብ',
            en:'Submitted by the individual or company'
        }
    },
        {
            value:'tip',
            name:{
                am:'ጥቆማ',
                en:'Tip'
            },
            desc:{
                am:'ከተቀመጠው መደበኛ አሰራር ውጪ ሆኖ ከተገኘ በማንኛውም አካል ሊቀርብ የሚችል',
                en:'Can be submitted by and person'
            }
        }


    ]

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'complaintType',
        onChange:val=>setFieldValue('complaintType',val),
        value:values.complaintType
           })


    const [screen, setScreen] = useState(1);
    const {t} = useTranslation();
    const group = getRootProps()
    const context =  useContext(FileComplaintContext)
    // console.log({context})
    const data = context?.briefingScreen;
    const data2 =   context?.complaintTypeScreen;


    const { language} = useI18next();
    if(!data) return <div>Loading</div>
    return (

        <motion.main
            initial={{opacity: 0, y: 200}}
            animate={{opacity: 1, x: 0, y: 0}}
            transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}>
            <Box>
                <Text mb="10" fontSize='2xl' fontWeight='bold' color="tomato">{data && data.title}</Text>

                {screen===1&&<motion.main
                    initial={{opacity: 0, x: 200}}
                    animate={{opacity: 1, x: 0, y: 0}}
                    transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.3
                    }}
                  >
                    {renderRichText(data.text)}
                    <Button m={'20px'} variant='grad' width={'300px'} onClick={()=>setScreen(2)}>{t("Choose To Tip Or Complain")}</Button>
                </motion.main>}

                {screen===2&&<motion.main
                    initial={{opacity: 0, x: 200}}
                    animate={{opacity: 1, x: 0, y: 0}}
                    transition={{
                        type: "spring",
                        mass: 0.35,
                        stiffness: 75,
                        duration: 0.3
                    }}>
                    <IconButton  variant='ghost' size='xs'  onClick={()=>setScreen(1)} aria-label='back' icon={<ArrowBackIcon />} />
                    {renderRichText(data2.text)}
                    <FormControl as='fieldset'>
                    <HStack m="10px" {...group}>
                        {options.map(({value,name,desc}) => {
                            const radio = getRadioProps({ value })
                            return (
                                <RadioCard key={value} {...radio}>
                                    <Box fontSize="xl" fontWeight="bold">{name[language]}</Box>
                                    <Box fontSize="sm">
                                        {desc[language]}
                                    </Box>
                                </RadioCard>
                            )
                        })}
                    </HStack>
                    </FormControl>
                    {/*<Button onClick={()=>setScreen(1)}>Back</Button>*/}
                </motion.main>}



            </Box>
        </motion.main>




    );
};

export default ComplaintBreif;

