import "niceware/browser/niceware"
import React, {useContext, useRef} from 'react';
import { navigate } from 'gatsby';
import {useFormikWizard} from "formik-wizard-form";
import ComplaintBreif from "./breif";
import ComplaintSource from "./complaintSource";
import ComplainantDetail from "./complainantDetail";
import * as Yup from 'yup';
import {Box, Divider, HStack, Spacer, Text, VStack, Button,Image,Flex,Link} from "@chakra-ui/react";
import {useSteps} from "chakra-ui-steps";
import {ComplaintStep} from "../stepper";
import PersonalDetail from "./personalDetail";
import ComplaintSummery from "./summery";
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import { gql, useMutation } from '@apollo/client';
import {M_CREATE_COMPLAINT} from "../../gql/complaint";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {  } from '@chakra-ui/icons';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import {useDisclosure} from "@chakra-ui/hooks";
import {fromHex, hexToBytes} from "../../helpers/conversion";
import { useReactToPrint } from 'react-to-print';
import {Container, Grid} from "@chakra-ui/layout";
import {FileComplaintContext} from "../../helpers/contexts";
const ComplaintWizardForm = () => {
    const context =  useContext(FileComplaintContext)
    console.log(context)
    // const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    //     initialStep: 0,
    // });

    const [finalValues, setFinalValues] = React.useState({});
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [copied, setCopied] = React.useState(false)
    const [codeCopied, setCodeCopied] = React.useState(false)
    const [complaintCode, setComplaintCode] = React.useState("92526bcf");
    const [createComplaint] = useMutation(M_CREATE_COMPLAINT);
    const {t} = useTranslation();
    const submitComplaint = async complaint => {

        try {
            const {data, extensions} = await createComplaint({ variables: {complaint:{...complaint,incidentDate:complaint.incidentDate.toString()}} });
            setComplaintCode(data.createComplaint.record.code)
            onOpen()

        }
       catch (e){
                console.log({e})
       }

    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const {
        currentStepIndex,
        values,
        renderComponent,
        handlePrev,
        handleNext,
        isNextDisabled,
        isPrevDisabled,
        isLastStep,
    } = useFormikWizard({
        initialValues: { mediaType: 'TV', applicant:{applicantType:"individual"}, fileAnonymously:false },
        onSubmit:submitComplaint,
        validateOnNext: true,
        activeStepIndex: 0,
        steps: [
            {
                component: ComplaintBreif,
                validationSchema: Yup.object().shape({
                    complaintType: Yup.string().required('Complaint Type is required'),

                   }),

            },
            {
                component: ComplaintSource,
                validationSchema: Yup.object().shape({

                    contentType: Yup.string().required('Content Type is required'),
                    incidentDate: Yup.string().required('Incident Date is required'),
                    // channelId: Yup.string().required('Channel is required'),
                    // programId: Yup.string().required('Program is required'),
                }),
            },
            {
                component:ComplainantDetail,
                validationSchema: Yup.object().shape({
                    category: Yup.string().required('Complaint Category is required'),
                    detailComplaint: Yup.string().min(10, "The detail Must be more than 10 characters").required('Detail Description is required'),
                }),
            },
            {
                component:PersonalDetail,
                validationSchema: Yup.object().shape({
                    fileAnonymously: Yup.boolean(),
                    applicant:  Yup.object().when("fileAnonymously", {
                        is: false,
                        then: Yup.object().shape({
                            name: Yup.string().required('Name is required'),
                            lastName: Yup.string().when("applicantType", {
                                is: "individual",
                                then: Yup.string().required('Last Name is required'),
                            }),


                            phoneNumber: Yup.string().required('Phone Number is required').test('len', 'Please check your phone number again', val => val && val.toString().length === 13)
                            // channelId: Yup.string().required('Channel is required'),
                            // programId: Yup.string().required('Program is required'),
                        })
                    })
                    }),

            },
            {
                component: ComplaintSummery
                // validationSchema: Yup.object().shape({
                //     designation: Yup.string().required('Designation is required'),
                // }),
            }
        ],
    });

    // const next = () => {
    //     handleNext()//.then(()=>nextStep())  ;
    //
    // }
    // const back = () => {
    //     handlePrev()//.then(()=>prevStep())  ;
    //
    //
    // }
    const goToHome = () => {
        onClose()
        navigate('/')

    }


    return (


        <Grid   width={{ sm: 'md', md: '3xl', lg: '3xl' }} mx="auto" className="max-w-md mx-auto" overflow='auto'    >

            <HStack h="full">

                <VStack  w={["sm","full"]}  p={5} h="full"  >
                        {renderComponent()}
                    <Spacer />
                    <Divider borderColor="gray.200" />
                    {values.complaintType&&<HStack width="full">
                        <Button size="sm" variant='outline' onClick={handlePrev} disabled={isPrevDisabled}>Back</Button>
                        <Spacer/>
                        <Button
                            size="md" variant='grad'
                            onClick={handleNext}
                            disabled={isNextDisabled}

                        >
                            {t(isLastStep ? "Finish" : "Next")}
                        </Button>
                        {/*<Box*/}
                        {/*    onClick={next}*/}
                        {/*    as='button'*/}
                        {/*    p={4}*/}
                        {/*    width={150}*/}
                        {/*    fontSize="xl"*/}
                        {/*    color='white'*/}
                        {/*    fontWeight='bold'*/}
                        {/*    borderRadius='3xl'*/}
                        {/*    bgGradient='linear(to-r, #FF6F00, #FF8F00)'*/}
                        {/*    _hover={{*/}
                        {/*        bgGradient: 'linear(to-l, #FF6F00, #FF8F00)',*/}
                        {/*    }}*/}
                        {/*    disabled={isNextDisabled}*/}
                        {/*>*/}
                        {/*    {isLastStep?"ጨርስ":"ቀጥል"}*/}

                        {/*</Box>*/}

                    </HStack>}

                </VStack>




                <Box width={{base: '250px', lg: "300px"}} roundedTopRight={{ sm: "3xl" }} roundedBottomRight={{ sm: "3xl" }} bg='primary' h="full"   >
                    <Box py="5" color="white" fontSize="xl">
                        <Text align="center" mx="auto" fontWeight="bold"><Trans>Make A Complaint</Trans></Text>
                    </Box>

                    <Box pl={5} >
                        <ComplaintStep activeStep={currentStepIndex}/>

                    </Box>


                </Box>



            </HStack>


            <Modal closeOnOverlayClick={false}  isOpen={isOpen} onClose={goToHome}>

                <ModalOverlay />
                <ModalContent maxW="lg" >
                    <ModalHeader>
                        <HStack>
                            <Trans i18nKey="complaintReceived">We have received your {{complaintType:t(values.complaintType)}}</Trans>
                            <Spacer/>
                            <Button onClick={handlePrint} size="sm"><Trans>Print</Trans></Button>
                        </HStack>


                    </ModalHeader>

                    <ModalBody ref={componentRef}>
                        <Text marginBottom={5}>
                            <Trans>Please use the following code to follow the status of your complaint</Trans>
                        </Text>

                        {complaintCode&&<HStack>
                            <VStack align='start' spacing={-1}>
                                <Text  fontSize='sm'><Trans>Complaint Followup Code</Trans></Text>
                                <HStack>

                                    <Text fontSize="2xl" fontWeight="bold">{complaintCode&&(typeof window !== `undefined`)&&window.niceware.bytesToPassphrase(fromHex(complaintCode)).join(' ')}</Text>
                                    <CopyToClipboard text={complaintCode&&(typeof window !== `undefined`)&&window.niceware.bytesToPassphrase(fromHex(complaintCode)).join(' ')}
                                                     onCopy={() =>setCopied(true) }>
                                        <Button variant='ghost' size='xs'>{copied?t('Copied'):t('Copy')}</Button>
                                    </CopyToClipboard>
                                </HStack>

                            </VStack>
                            <Spacer/>
                            <VStack spacing={-1.5}>
                                <Image src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${complaintCode}`}
                                       alt={complaintCode}/>
                                <Text mt={"20px"} fontSize="2xl" fontWeight="bold" >  {complaintCode}</Text>
                                <CopyToClipboard text={complaintCode}
                                                 onCopy={() =>setCodeCopied(true) }>
                                    <Button variant='outline' size='md'>{codeCopied?t('Copied'):t('Copy')}</Button>
                                </CopyToClipboard>


                            </VStack>

                        </HStack>
                        }                    </ModalBody>

                    <ModalFooter>
                        <CopyToClipboard text={complaintCode}
                                         onCopy={() =>setCodeCopied(true) }>
                        <Button variant="solid" colorScheme='blue'   mr={3} onClick={goToHome}>
                            <Trans>Close</Trans>
                        </Button>
                        </CopyToClipboard>

                        {/*<Button variant='ghost'>Secondary Actio</Button>*/}
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/**/}
            {/*<Box*/}
            {/*    pt={6}*/}
            {/*    lineHeight={["6", "7"]}*/}
            {/*    fontWeight="bold"*/}
            {/*    fontSize={{ sm: "lg" }}*/}
            {/*>*/}
            {/*    <Text color="black">Want to dig deeper into Chakra UI?</Text>*/}
            {/*    <p>*/}
            {/*        <Link*/}
            {/*            href="https://chakra-ui.com/docs/getting-started"*/}
            {/*            color="teal.600"*/}
            {/*            _hover={{*/}
            {/*                color: "teal.700",*/}
            {/*            }}*/}
            {/*            isExternal*/}
            {/*        >*/}
            {/*            {" "}*/}
            {/*            Read the docs &rarr;{" "}*/}
            {/*        </Link>*/}
            {/*    </p>*/}
            {/*</Box>*/}
        </Grid>






    );
};

export default ComplaintWizardForm;
