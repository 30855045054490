import React, {useContext} from 'react';
import {
    Box,
    Text,
    VStack,
    Select,
    StackDivider,
    FormControl,
    RadioGroup,
    HStack,
    Radio,
    FormHelperText,
    FormLabel,
    Textarea,
    Input
} from "@chakra-ui/react";
import {today} from '@internationalized/date';
import {motion} from "framer-motion";
import {Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {FileComplaintContext} from "../../helpers/contexts";
// import DatePicker from "../UI/DatePicker/date-picker";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
    AutoCompleteCreatable
} from "@choc-ui/chakra-autocomplete";
import {WrapItem, Wrap} from "@chakra-ui/layout";
import {FormErrorMessage} from "@chakra-ui/form-control";
import {DatePicker} from "../Calendar/DatePicker";
const ComplaintSource = ({errors, touched, values, handleChange,setFieldValue}) => {
    const data =  useContext(FileComplaintContext);

    const programs = data.programs.edges.map(e=>e.node)
    const channles = data.channels.edges.map(e=>e.node)
    const {t} = useTranslation();
    const { language} = useI18next();
    // console.log({errors})
    // console.log({values})
    return (
        <motion.main
            initial={{ opacity: 0, y: 200 }}
            animate={{ opacity: 1, x: 0, y:0 }}
            transition={{
                type: "spring",
                mass: 0.35,
                stiffness: 75,
                duration: 0.3
            }}>
            <Box>
                <VStack
                    divider={<StackDivider borderColor='gray.200' />}
                    spacing={4}
                    align='stretch'
                >



                    <FormControl as='fieldset'>
                        <FormLabel as='legend'><h1><Trans>What Media is your complaint about</Trans></h1></FormLabel>
                        <RadioGroup defaultValue='tv' name="mediaType" value={values.mediaType} onChange={val=>setFieldValue('mediaType',val)}>
                            <Wrap>
                                <WrapItem>  <Radio value='TV'><Trans>TV</Trans></Radio></WrapItem>

                                <WrapItem><Radio value='radio'><Trans>Radio</Trans></Radio></WrapItem>
                                <WrapItem><Radio value='printedPress'><Trans>Printed Press</Trans></Radio></WrapItem>
                                <WrapItem> <Radio value='onlineMedia'><Trans>Online Media</Trans></Radio></WrapItem>
                                <WrapItem><Radio value='other'><Trans>Other</Trans></Radio></WrapItem>
                            </Wrap>
                        </RadioGroup>

                    </FormControl>



                    <FormControl as='fieldset'>
                        <FormLabel as='legend'>  <h1>{(values.mediaType==='TV'||values.mediaType==='radio')?<Trans>What Channel is it on</Trans>:values.mediaType==='printedPress'?<Trans>What Publication is it on</Trans>:<Trans>Which Media is it on</Trans>}</h1></FormLabel>
                        {/*<Select placeholder={t('Select Option')} name="channelId" value={values.channelId} onChange={handleChange}>*/}
                        {/*    {channles.filter(c=>c.mediaType===values.mediaType).map(p=> <option value={p.channelId}>{p.name[language]}</option>)}*/}
                        {/*</Select>*/}
                        <AutoComplete   onCreateOption={console.log}  creatable openOnFocus name="channelId" onChange={val=>setFieldValue('channelId',val)}>
                            <AutoCompleteInput   placeholder={t('Select Option')} />
                            <AutoCompleteList>
                                {/*<AutoCompleteItem value={"test"} key="test">Test</AutoCompleteItem>*/}
                                {channles.filter(c=>c.mediaType===values.mediaType).concat(['creatable']).map((p, cid) => (
                                    p !== 'creatable'?
                                        <AutoCompleteItem
                                            key={`option-${cid}`}
                                            value={p.channelId}
                                            label=  {p.name[language]}
                                        >
                                            {p.name[language]}
                                        </AutoCompleteItem>:
                                        <AutoCompleteCreatable key={'option-create'}>
                                            {({ value }) => <span>Add {value} to List</span>}
                                        </AutoCompleteCreatable>

                                ))}




                            </AutoCompleteList>


                        </AutoComplete>
                        {/*<FormHelperText>Select only if you're a fan.</FormHelperText>*/}
                    </FormControl>

                    {values.mediaType&&
                    <FormControl as='fieldset' isInvalid={!!errors.contentType}>
                        <FormLabel as='legend'><h1><Trans>What kind of content is it</Trans></h1>
                        </FormLabel>
                        <RadioGroup  name="contentType" value={values.contentType}
                                     onChange={val => setFieldValue('contentType', val)}>
                            <HStack spacing='24px'>
                                {(values.mediaType==='TV'||values.mediaType==='radio')&&<Radio value='program'><Trans>Program</Trans></Radio>}
                                <Radio value='advertisement'><Trans>Advertisement</Trans></Radio>
                                <Radio value='news'><Trans>News</Trans></Radio>


                            </HStack>
                        </RadioGroup>
                        <FormErrorMessage>{errors.contentType}</FormErrorMessage>

                    </FormControl>}

                    {(values.channelId&&values.contentType==='program')&&<FormControl as='fieldset'>
                        <FormLabel as='legend'><h1><Trans>Which Program is it?</Trans></h1></FormLabel>
                        {/*<Select placeholder={t('Select Option')} name="programId" value={values.programId}*/}
                        {/*        onChange={handleChange}>*/}
                        {/*    {programs.filter(c => c.channel === values.channelId).map(p => <option*/}
                        {/*        value={p.programId}>{p.name[language]}</option>)}*/}

                        {/*</Select>*/}
                        <AutoComplete  onCreateOption={console.log}  creatable openOnFocus name="programId" onChange={val=>setFieldValue('programId',val)}>
                            <AutoCompleteInput   placeholder={t('Select Option')} />
                            <AutoCompleteList>
                                {/*<AutoCompleteItem value={"test"} key="test">Test</AutoCompleteItem>*/}
                                {programs.filter(c => c.channel === values.channelId).concat(['creatable']).map((p, cid) => (
                                    p !== 'creatable'?
                                        <AutoCompleteItem
                                            key={`option-${cid}`}
                                            value={p.programId.toString()}
                                            label=  {p.name[language]}
                                        >
                                            {/*{p.name[language]} <pre>{JSON.stringify(p, null, 2) }</pre>*/}
                                        </AutoCompleteItem>:
                                        <AutoCompleteCreatable key={'option-create'}>
                                            {({ value }) => <span>Add {value} to List</span>}
                                        </AutoCompleteCreatable>

                                ))}




                            </AutoCompleteList>


                        </AutoComplete>

                        <FormHelperText><Trans>If the program is not listed here you can type it
                            manually</Trans></FormHelperText>
                    </FormControl>}



                    {values.contentType!=='program'&&<FormControl as='fieldset'>
                        <FormLabel as='legend'><h1>{values.mediaType==='onlineMedia'?<Trans>Content Link</Trans>:<Trans>Content Subject</Trans>}</h1></FormLabel>

                        <Input
                            name="contentDescription" placeholder={t('describe the content here')} value={values.contentDescription}
                            onChange={handleChange}

                        />




                    </FormControl>}

                    {values.mediaType==='printedPress'&&
                    <FormControl as='fieldset'>
                        <FormLabel as='legend'><h1><Trans>Publication Info</Trans></h1></FormLabel>

                        <HStack>
                            <Input
                                name="pageNumber" placeholder={t('page number')} value={values.pageNumber}
                                onChange={handleChange}

                            />
                            <Input
                                name="author" placeholder={t('author')} value={values.pageNumber}
                                onChange={handleChange}

                            />
                        </HStack>

                    </FormControl>







                    }

                    <FormControl as='fieldset' isInvalid={!!errors.incidentDate}>
                        <FormLabel as='legend'><h1><Trans>When was it </Trans>{(values.mediaType==='TV'||values.mediaType==='radio')?<Trans>Broadcast</Trans>:<Trans>Published</Trans>}</h1></FormLabel>

                        {/*<DatePicker*/}
                        {/*    id="incident-date"*/}
                        {/*    dateFormat={(values.mediaType==='TV'||values.mediaType==='radio')?"dd MMMM yyyy 'at' HH:mm":"dd MMMM yyyy"}*/}
                        {/*    showTimeSelect={values.mediaType==='TV'||values.mediaType==='radio'}*/}
                        {/*    selectedDate={values.incidentDate}*/}
                        {/*    onChange={d=>setFieldValue('incidentDate',d)}*/}
                        {/*    showPopperArrow={true}*/}
                        {/*/>*/}
                        <DatePicker granularity={(values.mediaType==='TV'||values.mediaType==='radio')?'minute':'day'} value={values.incidentDate} onChange={d=>setFieldValue('incidentDate',d)} />


                        <FormHelperText><Trans>The exact date and time is important to review the actual content</Trans></FormHelperText>
                        <FormErrorMessage>{errors.incidentDate}</FormErrorMessage>
                    </FormControl>

                </VStack>


            </Box>
        </motion.main>
    );
};

export default ComplaintSource;
