import axios from "axios";

export function uploadFile(file,uploadPreset,fileName, cloudName='media-et') {


    let formData = new FormData();
    // formData.append("api_key", '');
    formData.append("file", file);
    if(fileName) {
        formData.append("public_id", fileName);
    }
    // formData.append("timestamp", timeStamp);
    formData.append("upload_preset", uploadPreset);

    return   axios
        .post(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, formData)

}

export async function  customUpload(p) {

    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76"
    // data: {}
    // file: File
    // uid: "rc-upload-1646110755887-2"
    // lastModified: 1645583350139
    // lastModifiedDate: Wed Feb 23 2022 05:29:10 GMT+0300 (East Africa Time) {}
    // name: "ask-hst@2x-102.jpg"
    // size: 158225
    // type: "image/jpeg"
    // webkitRelativePath: ""
    //     [[Prototype]]: File
    // filename: "file"
    // headers: {}
    // method: "post"
    // onError: ƒ onError(err, ret)
    // onProgress: ƒ onProgress(e)
    // onSuccess: ƒ onSuccess(ret, xhr)
    // withCredentials: false
    // console.log(p)

    try {
        const res = await uploadFile(p.file, p.action, p.filename)
        p.onSuccess(res)
    } catch (e) {
        p.onError(e)
    }
}
